import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, useMediaQuery } from '@mui/material';
import { ICandidateJobDto } from '@nploy/ui-infrastructure';
import moment from 'moment';
import theme from 'theme/theme';
import { useDislikeJob } from 'utils/swr/hooks/useDislikeJob';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useLikeJob } from 'utils/swr/hooks/useLikeJob';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { getJobs } from 'store/actions/jobsActions';
import Banner from 'components/Filters/Banner/Banner';
import { FindJobsPanel } from 'components/FindJobs';
import { withFindJobs } from 'components/FindJobs/with-find-jobs';
import {
  DetailedViewToggle,
  JobDetailedViewContainer,
  JobDetailedViewContent,
  JobsList,
} from 'components/JobsDashboard/';
import { IFindJobs } from './find-jobs.interface';
import { findJobsStyles } from './find-jobs.styles';

export const FindJobs: FC<IFindJobs> = withFindJobs(() => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const dispatch = useAppDispatch();
  const {
    jobs,
    filteredJobs,
    loading,
    pageIndex,
    hasNextPage,
    hasExternalNextJobsPage,
  } = useAppSelector(({ jobs: stateJobs }) => stateJobs);

  const { loading: filtersLoading, filtersValues } = useFiltersValues();
  const { dislike } = useDislikeJob();
  const { like } = useLikeJob();

  const [showDetails, setShowDetails] = useState<ICandidateJobDto | null>(null);
  const isShowManagerForADayBanner = useMemo(
    () => moment().isBetween('2022-03-22', '2022-04-22'),
    [],
  );

  const findJobDetails = useCallback(
    (detailsId: number) => filteredJobs?.find(({ id }) => id === detailsId),
    [filteredJobs],
  );

  const handleShowDetails = useCallback(
    (detailsId: number) => {
      const jobDetails = findJobDetails(detailsId);
      setShowDetails(jobDetails || null);
    },
    [filteredJobs],
  );

  const handleCloseDetails = useCallback(() => {
    setShowDetails(null);
  }, []);

  const handleLoadMoreJobs = useCallback(() => {
    dispatch(getJobs(filtersValues));
  }, [dispatch, filtersValues]);

  const handleAction = useCallback(
    (jobId: number, isExternalJob: boolean) => {
      if (isMobile) {
        handleCloseDetails();
      } else {
        const indexOfCurrentJob = filteredJobs?.findIndex(
          ({ id, isExternal = false }) => {
            if (isExternal && isExternalJob) {
              return id === jobId;
            }
            return id === jobId;
          },
        );

        if (
          !Number.isNaN(indexOfCurrentJob) &&
          indexOfCurrentJob >= 0 &&
          indexOfCurrentJob < filteredJobs.length - 1
        ) {
          handleShowDetails(filteredJobs[indexOfCurrentJob + 1]?.id);
        } else {
          handleCloseDetails();
        }
      }
    },
    [filteredJobs, handleCloseDetails, handleShowDetails, isMobile],
  );

  const handleApply = useCallback(
    async (jobId: number, externalApplyLink: string) => {
      await like(
        jobId,
        () => handleAction(jobId, !!externalApplyLink),
        null,
        externalApplyLink,
      );
    },
    [like, handleAction],
  );

  const handleDiscard = useCallback(
    async (jobId: number, isExternal: boolean) =>
      await dislike(
        jobId,
        () => handleAction(jobId, isExternal),
        null,
        isExternal,
      ),
    [dislike, handleAction],
  );

  return (
    <Container maxWidth={false} sx={{ maxWidth: '1416px' }}>
      {isShowManagerForADayBanner && <Banner />}
      <FindJobsPanel showDetails={!!showDetails}>
        <DetailedViewToggle
          showingDetails={!!showDetails}
          onShowDetails={handleShowDetails}
          onCloseDetails={handleCloseDetails}
          jobs={filteredJobs}
        />
      </FindJobsPanel>

      <Box component="section" sx={findJobsStyles.jobsListWrapper}>
        <JobsList
          showDetailsId={showDetails?.id}
          showingDetails={!!showDetails}
          onShowDetails={handleShowDetails}
          pageIndex={pageIndex}
          jobs={filteredJobs}
          loading={loading || filtersLoading}
          onLoadMore={handleLoadMoreJobs}
          hasNextPage={hasNextPage || hasExternalNextJobsPage}
        />

        {!!showDetails && (
          <JobDetailedViewContainer
            showingDetails={!!showDetails}
            onCloseDetails={handleCloseDetails}
          >
            <JobDetailedViewContent
              jobId={showDetails.id}
              job={showDetails}
              onApply={handleApply}
              onDiscard={handleDiscard}
            />
          </JobDetailedViewContainer>
        )}
      </Box>
    </Container>
  );
});

import React, { useCallback, useEffect, useState } from 'react';
import getCountryFromIP from 'utils/helpers/getCountryFromIP';
import { getFromLocalStorage } from 'utils/localStorage';
import { useAppDispatch } from 'hooks/*';
import { useFiltersValues } from './useFiltersValues';
import { useUser } from './useUser';

export const useCountry = () => {
  const dispatch = useAppDispatch();
  const [country, setCountry] = useState(null);
  const {
    user: { isAuthed },
  } = useUser();
  const { saveCountry } = useFiltersValues();

  const getCountryFromIp = useCallback(async () => {
    const storageCountry = getFromLocalStorage('nploy_country');
    if (storageCountry) {
      setCountry(storageCountry);
      await saveCountry(storageCountry);
    } else {
      const ipCountryRes = await getCountryFromIP();
      setCountry(ipCountryRes);
      await saveCountry(ipCountryRes);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthed) {
      getCountryFromIp();
    }
  }, [isAuthed]);

  return country;
};
